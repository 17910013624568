import React, {
  useState,
} from 'react';
import {
  addDoc,
  collection,
} from 'firebase/firestore';
import {
  Title,
  Text,
  Paper,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { db } from 'firebaseConfig';
import Frame from 'Components/Frame';
import SessionForm from 'Forms/Session';
import LoadingOverlayBox from 'Components/LoadingOverlayBox';

const Add = () => {
  const [loading, setLoading] = useState(false);

  const handleAddSession = async (data) => {
    console.log('handleAddSession:data', data)

    setLoading(true);

    try {
      const sessions = collection(db, 'sessions');

      data.attendance = [];
      data.cat = new Date();
      data.uat = new Date();

      const ref = await addDoc(sessions, data);
      console.log('handleAddSession:ref', ref);

      notifications.show({
        title: 'Operation Success',
        message: `Session ${data.type} added successfully`,
      });
    } catch (error) {
      console.error('handleAddSession:error', error);

      notifications.show({
        title: 'Operation Failed',
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  }

  const isLoadingOverlayBoxVisible = loading;

  return (
    <Frame label="Session List" href="/session/list">
      <Paper withBorder shadow="sm" radius="md" p={18}>
        <LoadingOverlayBox visible={isLoadingOverlayBoxVisible}>
          <Title order={3}>Add Session</Title>
          <Text size="sm" mb="md">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </Text>

          <SessionForm onSubmit={handleAddSession} />
        </LoadingOverlayBox>
      </Paper>
    </Frame>
  );
};

export default Add;