import React from 'react';

const Home = () => {
    return (
        <div className="Home">
            <ul>
                <li>
                    <a href="/volunteer/list">Volunteer List</a>
                </li>
                <li>
                    <a href="/session/list">Session List</a>
                </li>
            </ul>
        </div>
    );
};

export default Home;
