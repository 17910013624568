import React, {
    useRef,
    useState,
    useEffect,
    useCallback,
} from 'react';
import PropTypes from 'prop-types';
import jsQR from "jsqr";
import {
    Text,
} from '@mantine/core';

function QRCodeReader({ onScan }) {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [isReady, setIsReady] = useState(false);

    const scanQRCode = useCallback(() => {
        if (videoRef.current && canvasRef.current) {
            const video = videoRef.current;
            const canvas = canvasRef.current;
            const context = canvas.getContext('2d');

            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
            context.drawImage(video, 0, 0, canvas.width, canvas.height);

            const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
            const code = jsQR(imageData.data, imageData.width, imageData.height);

            if (code) {
                onScan(code.data);
            }
        }
    }, [onScan]);

    useEffect(() => {
        const startCamera = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                    videoRef.current.onloadedmetadata = () => {
                        videoRef.current.play();
                        setIsReady(true);
                    };
                }
            } catch (error) {
                console.error("startCamera:error", error);
            }
        };

        startCamera();
    }, []);

    useEffect(() => {
        let intervalId;
        if (isReady) {
            // Scan every 100ms
            intervalId = setInterval(scanQRCode, 100);
        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [isReady, scanQRCode]);

    const getStatusText = () => {
        if (isReady) {
            return 'Scanning';
        }

        return 'Initializing';
    }

    return (
        <div>
            <video
                ref={videoRef}
                autoPlay
                playsInline
            />
            <canvas ref={canvasRef} style={{ display: 'none' }} />
            <Text>
                {getStatusText()}
            </Text>
        </div>
    );
}

QRCodeReader.propTypes = {
    onScan: PropTypes.func,
};

export default QRCodeReader;