import React, {
  useState,
  useEffect,
} from 'react';
import {
  collection,
  getDocs,
  orderBy,
} from 'firebase/firestore';
import {
  ActionIcon,
  Menu,
  Table,
  Paper,
} from '@mantine/core';
import {
  IconDotsVertical,
} from '@tabler/icons-react';
import { db } from 'firebaseConfig';
import Frame from 'Components/Frame';
import LoadingOverlayBox from 'Components/LoadingOverlayBox';

const List = () => {
  const [loading, setLoading] = useState(false);
  const [sessions, setSessions] = useState([]);

  useEffect(() => {
    const fetchSessions = async () => {
      setLoading(true);

      try {
        const snapshot = await getDocs(collection(db, 'sessions'), orderBy('uat', 'desc'));
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSessions(data);
        console.log('fetchSessions:data', data);
      } catch (error) {
        console.error('Error fetching sessions: ', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSessions();
  }, []);

  const getUpdateUrl = (session) => {
    return `/session/${session.id}`;
  }

  const getAttendanceUrl = (session) => {
    return `/session/${session.id}/attendance`;
  }

  const getMenu = (session) => {
    return (
      <Menu width={180} shadow="md">
        <Menu.Target>
          <ActionIcon variant="outline" aria-label="Menu">
            <IconDotsVertical stroke={1} />
          </ActionIcon>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item component="a" href={getUpdateUrl(session)}>
            Update
          </Menu.Item>

          <Menu.Item component="a" href={getAttendanceUrl(session)}>
            Attendance
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    );
  }

  const rows = sessions.map((session) => {
    const date = new Date(session.date.seconds * 1000 + session.date.nanoseconds / 1000000);

    return (
      <Table.Tr key={session.id}>
        <Table.Td>{session.type}</Table.Td>
        <Table.Td>{date.toDateString()}</Table.Td>
        <Table.Td>{session.attendance.length}</Table.Td>
        <Table.Td>{getMenu(session)}</Table.Td>
      </Table.Tr>
    );
  });

  const isLoadingOverlayBoxVisible = loading;

  return (
    <Frame label="Add Session" href="/session/add">
      <LoadingOverlayBox visible={isLoadingOverlayBoxVisible}>
        <Paper withBorder shadow="sm" radius="md">
          {loading === false &&
            <Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Type</Table.Th>
                  <Table.Th>Date</Table.Th>
                  <Table.Th>Attendance</Table.Th>
                  <Table.Th />
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{rows}</Table.Tbody>
            </Table>
          }
        </Paper>
      </LoadingOverlayBox>
    </Frame>
  );
};

export default List;