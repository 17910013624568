import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from 'react-router-dom';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import {
    Box,
    Button,
    Container,
    Title,
    Text,
    Group,
    useMantineTheme,
} from '@mantine/core';
import config from 'config';
import { auth } from 'firebaseConfig';
import { useAuth } from 'Provider/Auth';
import Guard from 'Components/Guard';
import SignIn from 'Pages/Sign/In';
import Home from 'Pages/Home';
import VolunteerList from 'Pages/Volunteer/List';
import VolunteerAdd from 'Pages/Volunteer/Add';
import VolunteerUpdate from 'Pages/Volunteer/Update';
import SessionList from 'Pages/Session/List';
import SessionAdd from 'Pages/Session/Add';
import SessionUpdate from 'Pages/Session/Update';
import Attendance from 'Pages/Session/Attendance';

const Scaffold = () => {
    const user = useAuth();
    const theme = useMantineTheme();
    const backgroundColor = theme.colors.gray[0];

    const handleSignOut = () => {
        console.log('handleSignOut')
        auth.signOut();
    }

    return (
        <Container bg={backgroundColor} size="md" py="sm">
            <Group justify="space-between" mb="xl">
                <Box>
                    <Title order={1}>
                        {config.app.name}
                    </Title>
                    <Text size="sm" mb="md">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit
                    </Text>
                </Box>

                <Box>
                    <Button component="a" href="/">
                        Home
                    </Button>

                    {user &&
                        <Button variant="light" onClick={handleSignOut}>
                            Sign Out
                        </Button>
                    }

                    {user === null &&
                        <Button component="a" href="/sign/in">
                            Sign In
                        </Button>
                    }
                </Box>
            </Group>

            <Router>
                <Routes>
                    <Route path="/" element={
                        <Guard>
                            <Home />
                        </Guard>
                    } />

                    <Route path="/sign/in" element={<SignIn />} />

                    <Route path="/volunteer/list" element={
                        <Guard>
                            <VolunteerList />
                        </Guard>
                    } />
                    <Route path="/volunteer/add" element={
                        <Guard>
                            <VolunteerAdd />
                        </Guard>
                    } />
                    <Route path="/volunteer/:id" element={
                        <Guard>
                            <VolunteerUpdate />
                        </Guard>
                    } />
                    {/* Alias */}
                    <Route path="/volunteer" element={
                        <Navigate to="/volunteer/list" />
                    } />

                    <Route path="/session/list" element={
                        <Guard>
                            <SessionList />
                        </Guard>
                    } />
                    <Route path="/session/add" element={
                        <Guard>
                            <SessionAdd />
                        </Guard>
                    } />
                    <Route path="/session/:id" element={
                        <Guard>
                            <SessionUpdate />
                        </Guard>
                    } />
                    <Route path="/session/:id/attendance" element={
                        <Guard>
                            <Attendance />
                        </Guard>
                    } />
                    {/* Alias */}
                    <Route path="/session" element={
                        <Navigate to="/session/list" />
                    } />

                </Routes>
            </Router>
        </Container>
    );
};

export default Scaffold;
