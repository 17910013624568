import React, {
  useEffect,
  useState,
} from 'react';
import {
  doc,
  getDoc,
  updateDoc,
} from 'firebase/firestore';
import {
  Title,
  Text,
  Paper,
} from '@mantine/core';
import { useParams } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { db } from 'firebaseConfig';
import Frame from 'Components/Frame';
import SessionForm from 'Forms/Session';
import LoadingOverlayBox from 'Components/LoadingOverlayBox';

const Update = () => {
  const { id } = useParams();

  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState(null);

  useEffect(() => {
    const fetchSession = async () => {
      console.log('fetchSession:id', id);

      setLoading(true);

      try {
        const ref = doc(db, 'sessions', id);
        const snapshot = await getDoc(ref);

        if (snapshot.exists()) {
          const data = snapshot.data();
          data.date = new Date(data.date.seconds * 1000 + data.date.nanoseconds / 1000000);
          setSession(data);
          console.log('fetchSession:data', data);
        } else {
          console.log('fetchSession:data', null);

          notifications.show({
            title: 'Operation Failed',
            message: `Could not find session ${id}`,
          });
        }
      } catch (error) {
        console.error('fetchSession:error', error);

        notifications.show({
          title: 'Operation Failed',
          message: error.message,
        });
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchSession();
    }
  }, [id]);

  const handleUpdateSession = async (data) => {
    console.log('handleUpdateVolunter:data', data);

    setLoading(true);

    try {
      data.uat = new Date();

      await updateDoc(doc(db, 'sessions', id), data);

      notifications.show({
        title: 'Operation Success',
        message: `Session ${data.type} updated successfully`,
      });
    } catch (error) {
      console.error('handleUpdateVolunter:error', error);

      notifications.show({
        title: 'Operation Failed',
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  }

  const isLoadingOverlayBoxVisible = loading;

  return (
    <Frame label="Session List" href="/session/list">
      <Paper withBorder shadow="sm" radius="md" p={18}>
        <LoadingOverlayBox visible={isLoadingOverlayBoxVisible}>
          <Title order={3}>Add Session</Title>
          <Text size="sm" mb="md">
            {id}
          </Text>

          <SessionForm onSubmit={handleUpdateSession} session={session} />
        </LoadingOverlayBox>
      </Paper>
    </Frame>
  );
};

export default Update;