import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCx3i7pqFkVCW2ehqu483_scIMp5vHrxmU',
  authDomain: 'bowa-people.firebaseapp.com',
  projectId: 'bowa-people',
  storageBucket: 'bowa-people.appspot.com',
  messagingSenderId: '908079013887',
  appId: '1:908079013887:web:683e464e558d314a4811ca',
  measurementId: 'G-9RP3CK781H'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const db = getFirestore(app);