import React, {
    useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
    Autocomplete,
    Button,
} from '@mantine/core';
import {
    DateInput,
} from '@mantine/dates';
import {
    useForm,
} from '@mantine/form';
import dayjs from 'dayjs';
import config from 'config';

const Session = ({ onSubmit, session }) => {
    const form = useForm({
        initialValues: {
            type: '',
            date: '',
        },

        validate: {
            type: (value) => config.session.types.includes(value) ? null : 'Invalid session type',
            date: (value) => dayjs(value).isValid() ? null : 'Invalid date',
        },
    });

    useEffect(() => {
        if (session) {
            form.setValues(session);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session]);

    return (
        <form noValidate onSubmit={form.onSubmit(onSubmit)}>
            <Autocomplete
                label="Type"
                placeholder="Advance"
                data={config.session.types}
                {...form.getInputProps('type')} 
            />

            <DateInput
                label="Date"
                placeholder={dayjs('1999-07-03').toDate().toDateString()}
                required
                mt="md"
                {...form.getInputProps('date')}
            />

            <Button type="submit" fullWidth mt="md">
                Save
            </Button>

            <Button type="button" variant="light" fullWidth mt="md" onClick={form.reset}>
                Reset
            </Button>
        </form>
    );
};

Session.propTypes = {
    onSubmit: PropTypes.func,
    session: PropTypes.object,
};

export default Session;
