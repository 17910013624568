import React, {
  useEffect,
  useState,
} from 'react';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import {
  Button,
  TextInput,
  Title,
  Text,
  Paper,
  Divider,
} from '@mantine/core';
import { useParams } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { db } from 'firebaseConfig';
import Frame from 'Components/Frame';
import LoadingOverlayBox from 'Components/LoadingOverlayBox';
import QRCodeReader from 'Components/QrCodeReader';

const Attendance = () => {
  const { id } = useParams();

  const [email, setEmail] = useState('');

  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState(null);

  useEffect(() => {
    const fetchSession = async () => {
      console.log('fetchSession:id', id);

      setLoading(true);

      try {
        const ref = doc(db, 'sessions', id);
        const snapshot = await getDoc(ref);

        if (snapshot.exists()) {
          const data = snapshot.data();
          data.date = new Date(data.date.seconds * 1000 + data.date.nanoseconds / 1000000);
          setSession(data);
          console.log('fetchSession:data', data);
        } else {
          console.log('fetchSession:data', null);

          notifications.show({
            title: 'Operation Failed',
            message: `Could not find session ${id}`,
          });
        }
      } catch (error) {
        console.error('fetchSession:error', error);

        notifications.show({
          title: 'Operation Failed',
          message: error.message,
        });
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchSession();
    }
  }, [id]);

  const handleAttendance = async (volunteer) => {
    if (loading) {
      return;
    }

    if (volunteer.length === 0) {
      return;
    }

    console.log('handleAttendance:volunteer', volunteer);

    setLoading(true);

    try {
      if (session.attendance.includes(volunteer)) {
        throw new Error(`Attendance already marked for ${volunteer}`);
      }

      session.attendance.push(volunteer);

      await updateDoc(doc(db, 'sessions', id), session);

      alert(`Added ${volunteer}'s attendance in session ${session.type} successfully`);
    } catch (error) {
      console.error('handleAttendance:error', error);

      notifications.show({
        title: 'Operation Failed',
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  }

  const handleAttendanceByEmail = async () => {
    if (loading) {
      return;
    }

    console.log('handleAttendanceByEmail:email', email);

    setLoading(true);

    try {
      const volunteers = collection(db, 'volunteers');

      const q = query(volunteers, where('email', '==', email));
      const snapshot = await getDocs(q);
      console.log('handleAddVolunteer:snapshot', snapshot);

      if (snapshot.empty) {
        throw Error(`Volunteer ${email} not found`);
      }

      await handleAttendance(snapshot.docs.at(0).id);
    } catch (error) {
      console.error('handleAddVolunter:error', error);

      notifications.show({
        title: 'Operation Failed',
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  }

  const isLoadingOverlayBoxVisible = loading;
  const isAttendanceByEmailButtonDisabled = loading;

  return (
    <Frame label="Session List" href="/session/list">
      <Paper withBorder shadow="sm" radius="md" p={18}>
        <LoadingOverlayBox visible={isLoadingOverlayBoxVisible}>
          <Title order={3}>Attendance</Title>
          <Text size="sm" mb="md">
            {id}
          </Text>

          <TextInput
            label="Email"
            placeholder="volunteer@beatsofwa.org"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />

          <Button mt="xs" disabled={isAttendanceByEmailButtonDisabled} onClick={handleAttendanceByEmail}>Submit</Button>

          <Divider my="md" />

          <QRCodeReader onScan={(data) => handleAttendance(data)} />
        </LoadingOverlayBox>
      </Paper>
    </Frame>
  );
};

export default Attendance;