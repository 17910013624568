import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Container,
  Group,
} from '@mantine/core';

const Frame = ({ children, label, href }) => {
  return (

    <Container size="md" mb="xl">
      <Group justify="end" mb="md">
        <Button component="a" href={href}>
          {label}
        </Button>
      </Group>

      {children}
    </Container>
  );
};

Frame.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  href: PropTypes.string,
};

export default Frame;