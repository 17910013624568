import React from 'react';
import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/dates/styles.css';
import {
  DEFAULT_THEME,
  MantineProvider,
  createTheme,
} from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { AuthProvider } from 'Provider/Auth';
import Scaffold from 'Components/Scaffold';

dayjs.extend(utc);
dayjs.extend(timezone);

const theme = createTheme({
  ...DEFAULT_THEME,
  primaryColor: 'dark',
});

const App = () => {
  return (
    <div className="App">
      <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
        <Notifications />
        <AuthProvider>
          <Scaffold />
        </AuthProvider>
      </MantineProvider>
    </div>
  );
};

export default App;
