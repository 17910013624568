import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    LoadingOverlay,
} from '@mantine/core';

const LoadingOverlayBox = ({ children, visible }) => {
    return (
        <Box pos="relative">
            <LoadingOverlay
                visible={visible}
                overlayProps={{
                    radius: 'sm',
                    blur: 2,
                }}
                loaderProps={{ type: 'bars' }}
            />

            {children}
        </Box>
    );
};

LoadingOverlayBox.propTypes = {
    children: PropTypes.node,
    visible: PropTypes.bool,
};

export default LoadingOverlayBox;
