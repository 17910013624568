import React from 'react';
import PropTypes from 'prop-types';
import { useAuth } from 'Provider/Auth';

const Guard = ({ children }) => {
    const user = useAuth();

    if (user === null) {
        return (
            <div className='Guard'>
                Unauthorized
            </div>
        )
    } else {
        return (
            children
        );
    }
};

Guard.propTypes = {
    children: PropTypes.node,
};

export default Guard;
