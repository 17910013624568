import React, {
  useState,
  useEffect,
} from 'react';
import {
  collection,
  getDocs,
} from 'firebase/firestore';
import {
  ActionIcon,
  Menu,
  Table,
  Paper,
} from '@mantine/core';
import {
  IconDotsVertical,
} from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import dayjs from 'dayjs';
import * as mail from 'Utils/mail';
import { db } from 'firebaseConfig';
import Frame from 'Components/Frame';
import LoadingOverlayBox from 'Components/LoadingOverlayBox';

const List = () => {
  const [loading, setLoading] = useState(false);
  const [volunteers, setVolunteers] = useState([]);

  useEffect(() => {
    const fetchVolunteers = async () => {
      setLoading(true);

      try {
        const snapshot = await getDocs(collection(db, 'volunteers'));
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setVolunteers(data);
        console.log('fetchVolunteers:data', data);
      } catch (error) {
        console.error('Error fetching volunteers: ', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVolunteers();
  }, []);

  const handleSendQr = async (volunteer) => {
    try {
      mail.sendQr(volunteer);

      notifications.show({
        title: 'Operation Success',
        message: `Qr code sent successfully to ${volunteer.email}`,
      });
    } catch (error) {
      notifications.show({
        title: 'Operation Failed',
        message: error.message,
      });
    }
  }

  const getUpdateUrl = (volunteer) => {
    return `/volunteer/${volunteer.id}`;
  }


  const getMenu = (volunteer) => {
    return (
      <Menu width={180} shadow="md">
        <Menu.Target>
          <ActionIcon variant="outline" aria-label="Menu">
            <IconDotsVertical stroke={1} />
          </ActionIcon>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item component="a" href={getUpdateUrl(volunteer)}>
            Update
          </Menu.Item>

          <Menu.Item onClick={() => handleSendQr(volunteer)}>
            Send QR
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    );
  }

  const getAge = (volunteer) => {
    if (volunteer.dob) {
      const now = dayjs();
      const dob = dayjs(new Date(volunteer.dob.seconds * 1000 + volunteer.dob.nanoseconds / 1000000));
      return now.diff(dob, 'year');
    } else {
      return 'NA';
    }
  }

  const rows = volunteers.map((volunteer) => {

    return (
      <Table.Tr key={volunteer.id}>
        <Table.Td>{volunteer.name.first} {volunteer.name.last}</Table.Td>
        <Table.Td>{volunteer.email}</Table.Td>
        <Table.Td>{getAge(volunteer)}</Table.Td>
        <Table.Td>{getMenu(volunteer)}</Table.Td>
      </Table.Tr>
    );
  });

  const isLoadingOverlayBoxVisible = loading;

  return (
    <Frame label="Add Volunteer" href="/volunteer/add">
      <LoadingOverlayBox visible={isLoadingOverlayBoxVisible}>
        <Paper withBorder shadow="sm" radius="md">
          {loading === false &&
            <Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>Name</Table.Th>
                  <Table.Th>Email</Table.Th>
                  <Table.Th>Age</Table.Th>
                  <Table.Th />
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>{rows}</Table.Tbody>
            </Table>
          }
        </Paper>
      </LoadingOverlayBox>
    </Frame>
  );
};

export default List;