import emailjs from "@emailjs/browser";

const serviceId = 'service_dywom39';
const publicKey = 'i1492xe_dtxwQ_wUo';

export const sendQr = async (volunteer) => {
    const templateId = 'template_sl6acrc';

    try {
        await emailjs.send(serviceId, templateId, volunteer, publicKey);
        console.log('sendQr:success');
    } catch (error) {
        console.log('sendQr:error', error);
    }
}