import React, {
  useState,
  useEffect,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Paper,
  Title,
  Text,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from 'firebaseConfig';
import { useAuth } from 'Provider/Auth';
import SignInForm from 'Forms/Sign/In';
import LoadingOverlayBox from 'Components/LoadingOverlayBox';

const In = () => {
  const user = useAuth();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      navigate('/home');
    }
  }, [user, navigate]);

  const handleSignIn = async (data) => {
    console.log('handleSignIn:data', data);

    setLoading(true);

    const { email, password } = data;
    try {
      await signInWithEmailAndPassword(auth, email, password);
      console.log('handleSignIn:success');
    } catch (error) {
      console.error('handleSignIn:error', error);

      notifications.show({
        title: 'Authentication Failed',
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const isLoadingOverlayBoxVisible = loading;

  return (
    <Container size="xs" mb="xl">
      <Paper withBorder shadow="sm" radius="md" p={18}>
        <LoadingOverlayBox visible={isLoadingOverlayBoxVisible}>
          <Title order={3}>Sign In</Title>
          <Text size="sm" mb="md">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </Text>

          <SignInForm onSubmit={handleSignIn} />
        </LoadingOverlayBox>
      </Paper>
    </Container>
  );
};

export default In;