import React, {
  useState,
} from 'react';
import {
  query,
  where,
  getDocs,
  addDoc,
  collection,
} from 'firebase/firestore';
import {
  Title,
  Text,
  Paper,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { db } from 'firebaseConfig';
import Frame from 'Components/Frame';
import VolunteerForm from 'Forms/Volunteer';
import LoadingOverlayBox from 'Components/LoadingOverlayBox';
import * as mail from 'Utils/mail';

const Add = () => {
  const [loading, setLoading] = useState(false);

  const handleAddVolunteer = async (data) => {
    console.log('handleAddVolunter:data', data)

    setLoading(true);

    try {
      const volunteers = collection(db, 'volunteers');

      const q = query(volunteers, where('email', '==', data.email));
      const snapshot = await getDocs(q);
      console.log('handleAddVolunteer:snapshot', snapshot);

      if (snapshot.empty === false) {
        throw Error(`Email ${data.email} already exists. Please use a different email`);
      }

      data.cat = new Date();
      data.uat = new Date();

      const ref = await addDoc(volunteers, data);
      console.log('handleAddVolunter:ref', ref);

      data.id = ref.id;
      await mail.sendQr(data);

      notifications.show({
        title: 'Operation Success',
        message: `Volunteer ${data.name.first} ${data.name.last} added successfully`,
      });
    } catch (error) {
      console.error('handleAddVolunter:error', error);

      notifications.show({
        title: 'Operation Failed',
        message: error.message,
      });
    } finally {
      setLoading(false);
    }
  }

  const isLoadingOverlayBoxVisible = loading;

  return (
    <Frame label="Volunteer List" href="/volunteer/list">
      <Paper withBorder shadow="sm" radius="md" p={18}>
        <LoadingOverlayBox visible={isLoadingOverlayBoxVisible}>
          <Title order={3}>Add Volunteer</Title>
          <Text size="sm" mb="md">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit
          </Text>

          <VolunteerForm onSubmit={handleAddVolunteer} />
        </LoadingOverlayBox>
      </Paper>
    </Frame>
  );
};

export default Add;