import React, { 
    useEffect, 
} from 'react';
import PropTypes from 'prop-types';
import {
    TextInput,
    Button,
} from '@mantine/core';
import {
    DateInput,
} from '@mantine/dates';
import {
    useForm,
    isEmail,
    hasLength,
} from '@mantine/form';
import dayjs from 'dayjs';

const Volunteer = ({ onSubmit, volunteer }) => {
    const form = useForm({
        initialValues: {
            name: {
                first: '',
                last: '',
            },
            email: '',
            phone: '',
            dob: '',
        },

        validate: {
            name: {
                first: hasLength({
                    min: 1,
                    max: 32,
                }, 'First name must be least 1 and most 32 characters long'),

                last: hasLength({
                    min: 1,
                    max: 32,
                }, 'Last name must be least 1 and most 32 characters long'),
            },
            email: isEmail('Invalid email address'),
            phone: (value) => /^\d{10}$/.test(value) ? null : 'Invalid phone number',
            dob: (value) => dayjs(value).isValid() ? null : 'Invalid date of birth',
        },
    });

    useEffect(() => {
        if (volunteer) {
            form.setValues(volunteer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [volunteer]);

    return (
        <form noValidate onSubmit={form.onSubmit(onSubmit)}>
            <TextInput
                label="First Name"
                placeholder="Shivam"
                required
                {...form.getInputProps('name.first')}
            />

            <TextInput
                label="Last Name"
                placeholder="Last"
                required
                mt="md"
                {...form.getInputProps('name.last')}
            />

            <TextInput
                label="Email"
                placeholder="volunteer@beatsofwa.org"
                required
                mt="md"
                {...form.getInputProps('email')}
            />

            <TextInput
                label="Phone"
                placeholder="911"
                required
                mt="md"
                {...form.getInputProps('phone')}
            />

            <DateInput
                label="Date of Birth"
                placeholder={dayjs('1999-07-03').toDate().toDateString()}
                required
                mt="md"
                {...form.getInputProps('dob')}
            />

            <Button type="submit" fullWidth mt="md">
                Save
            </Button>

            <Button type="button" variant="light" fullWidth mt="md" onClick={form.reset}>
                Reset
            </Button>
        </form>
    );
};

Volunteer.propTypes = {
    onSubmit: PropTypes.func,
    volunteer: PropTypes.object,
};

export default Volunteer;
