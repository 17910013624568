import React from 'react';
import PropTypes from 'prop-types';
import {
    TextInput,
    PasswordInput,
    Group,
    Checkbox,
    Anchor,
    Button,
} from '@mantine/core';
import {
    useForm,
    isEmail,
    hasLength,
} from '@mantine/form';

const In = ({ onSubmit }) => {
    const form = useForm({
        initialValues: {
            email: 'admin@beatsofwa.org',
            password: '',
        },

        validate: {
            email: isEmail('Invalid email address'),
            password: hasLength({
                min: 6,
                max: 32,
            }, 'Password must be least 1 and most 32 characters long'),
        },
    });

    return (
        <form noValidate onSubmit={form.onSubmit(onSubmit)}>
            <TextInput
                label="Email"
                placeholder="admin@beatsofwa.org"
                required
                {...form.getInputProps('email')}
            />

            <PasswordInput
                label="Password"
                placeholder="Your little secret"
                required
                mt="md"
                {...form.getInputProps('password')}
            />

            <Group justify="space-between" mt="lg">
                <Checkbox label="Remember me" />
                <Anchor component="button" size="sm">
                    Forgot password?
                </Anchor>
            </Group>

            <Button type="submit" fullWidth mt="md">
                Sign In
            </Button>
        </form>
    );
};

In.propTypes = {
    onSubmit: PropTypes.func,
};

export default In;
